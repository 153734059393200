/* eslint-disable import/prefer-default-export,no-param-reassign,no-bitwise */
import {
  forIn, isObject, get,
} from 'lodash';
import isEqual from 'react-fast-compare';

// like lodash's inRange but fully exclusive
export const inRange = (number, start, end) => {
  if (number === start || number === end) {
    return false;
  }

  return Math.min(start, number) === start && Math.max(number, end) === end;
};

export function omitDeep(obj, keysToOmit = [], keyToIgnore = '') {
  return forIn(obj, (value, key) => {
    if (isObject(value) && key !== keyToIgnore) {
      omitDeep(value, keysToOmit, keyToIgnore);
    } else if (keysToOmit.includes(key)) {
      delete obj[key];
    }
  });
}

export function compare(current, next, keys) {
  return keys.every((key) => {
    const currentValue = get(current, key, '');
    const nextValue = get(next, key, '');

    if (currentValue === nextValue) {
      return true;
    }

    if (typeof currentValue === 'function' && typeof nextValue === 'function') {
      const functionsAreEqual = currentValue.toString() === nextValue.toString();
      return functionsAreEqual;
    }

    return isEqual(currentValue, nextValue);
  });
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getAllLessonIdsForCourse(courseContent) {
  const lessons = [];

  if (courseContent) {
    courseContent.forEach((contentElement) => {
      if (contentElement.type === 'lesson') {
        lessons.push(contentElement._id);
      } else {
        contentElement.lessons.forEach((seriesLesson) => {
          lessons.push(seriesLesson._id);
        });
      }
    });
  }

  return lessons;
}

export function getFilenameFromUrl(url, extension = false) {
  if (!url) return '';
  if (extension) return url.substring(url.lastIndexOf('/') + 1);
  return url.match(/([^/]+)(?=\.\w+$)/)[0];
}

export function getFileExtension(filename) {
  return filename.substring(filename.lastIndexOf('.'));
}

export const getLessonPreviewTab = id => `lesson-preview_${id}`;
export const getGrainPreviewTab = id => `grain-preview_${id}`;

export const getCookie = (key) => {
  const regex = new RegExp(`(?:(?:^|.*; s*)${key}s*=s*([^;]*).*$)|^.*$`, 'g');
  return document.cookie.replace(regex, '$1');
};

export const downloadBlob = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};
