import React from 'react';
import { FormattedMessage } from 'react-intl';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Link from '../Link';

import s from './Header.module.pcss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userMenuOpen: false,
      userMenuAnchorEl: null,
    };
  }

  handleUserMenuClick = (event) => {
    this.setState({
      userMenuOpen: true,
      userMenuAnchorEl: event.currentTarget,
    });
  };

  closeUserMenu = () => {
    this.setState({
      userMenuOpen: false,
      userMenuAnchorEl: null,
    });
  };

  render() {
    const { userMenuOpen, userMenuAnchorEl } = this.state;

    return (
      <AppBar className={s.fixedHeader}>
        <Toolbar className={s.toolbar}>
          <a href="/">
            <div className={s.logo}>
              <img src="/logo-gg-studio.png" alt="logo" />
            </div>
          </a>
          <div className={s.gymglishSeparator} />
          <div className={s.projectName}>Editor</div>

          <div className={s.grow} />

          <div>
            <FormattedMessage id="header.help" defaultMessage="Help">
              {translation => (
                <Link
                  to="https://help.gymglish.com/l/en/category/qrjre2mpy8-gymglish-studio"
                  toNewTab
                  className={s.helpLink}
                >
                  {translation}
                </Link>
              )}
            </FormattedMessage>

            <IconButton
              aria-owns={userMenuOpen ? 'gymglish-header-user-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleUserMenuClick}
              className={s.userMenu}
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="gymglish-header-user-menu"
              anchorEl={userMenuAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={userMenuOpen}
              onClose={this.closeUserMenu}
            >
              <FormattedMessage id="header.logout" defaultMessage="Logout">
                {translation => (
                  <MenuItem>
                    <a href="/auth/logout" className={s.logoutLink}>{translation}</a>
                  </MenuItem>
                )}
              </FormattedMessage>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
