import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: grey,
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '20px',
        textTransform: 'none',
      },
      raisedSecondary: {
        color: teal[500],
        backgroundColor: 'white',
        border: `1px solid ${teal[500]}`,
        '&:hover': {
          backgroundColor: grey[100],
        },
      },
    },
    MuiIconButton: {
      root: {
        width: '24px',
        height: '24px',
      },
      label: {
        marginTop: '-12px', // this is causing trouble
      },
    },
    MuiCheckbox: {
      root: {
        top: 6, // This counteracts the marginTop -12 above
      },
    },
    MuiTypography: {
      display2: {
        fontWeight: 700,
        fontSize: '32px',
        color: grey[600],
      },
      display1: {
        fontWeight: 400,
        fontSize: '28px',
        color: grey[600],
      },
      subheading: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: 'inherit',
        color: grey[600],
      },
      body2: {
        color: grey[600],
        fontWeight: 500,
        fontSize: '14px',
      },
      caption: {
        fontWeight: 500,
      },
    },
    MuiChip: {
      label: {
        // paddingLeft: 0,
      },
      avatar: {
        width: 26,
        height: 26,
        marginRight: 10,
        marginLeft: 6,
      },
      deleteIcon: {
        color: 'white',
        width: 18,
      },
    },
    MuiSwitch: {
      icon: {
        marginTop: 12,
      },
    },
  },
});

export default theme;
