import { sortBy, uniqBy, isEmpty } from 'lodash';
import * as types from '../../../../constants';

export default function (state, { type, payload, error }) {
  switch (type) {
    case types.FETCH_UNITS_LIST_REQUEST:
      return { ...state, fetchingUnits: true };
    case types.FETCH_UNITS_LIST_SUCCESS:
      return {
        ...state,
        units: payload.units.covers,
        fetchingUnits: false,
      };
    case types.FETCH_UNITS_LIST_FAILURE:
      return { ...state, error, fetchingUnits: false };
    case types.FETCH_SINGLE_UNIT_SUCCESS: {
      const { unit } = payload;
      let newUnits = [...state.units];
      const existingUnitIndex = newUnits.findIndex(u => u._id === unit._id);

      if (existingUnitIndex >= 0) {
        newUnits[existingUnitIndex] = unit;
      } else {
        newUnits = [...newUnits, unit];
      }

      return {
        ...state,
        units: newUnits,
      };
    }
    case types.ADD_ANSWER_SUCCESS: {
      const { currentAnswer, answers, selectedBracket } = payload;

      // find answer index
      const index = answers[selectedBracket].findIndex(a => a._id === currentAnswer._id);

      if (index >= 0) {
        currentAnswer.index = index;
      }

      return {
        ...state,
        currentAnswer,
      };
    }
    case types.FETCH_LESSON_ITEM_SUCCESS: {
      const newState = {
        ...state,
        data: {
          ...state.data,
          content: sortBy([
            payload.contentItem,
            ...state.data.content.filter(c => c.data._id !== payload.contentItem.data._id),
          ], 'order'),
        },
      };
      const stateHasCurrentAnswer = !isEmpty(state.currentAnswer);
      const isTest = payload.contentItem.type === 'test';

      // update current answer
      if (isTest && stateHasCurrentAnswer) {
        Object.values(payload.contentItem.data.answers)
          .forEach(answers => answers.forEach((answer, index) => {
            if (answer._id === state.currentAnswer._id) {
              newState.currentAnswer = answer;
              newState.currentAnswer.index = index;
            }
          }));
      }

      return newState;
    }
    case types.ADD_MAJOR_UNIT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.testId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  units: {
                    ...c.data.units,
                    [payload.selectedBracket]: uniqBy([...(c.data.units[payload.selectedBracket] || []), { _id: payload.unitId }], '_id'),
                  },
                },
              };
            }

            return c;
          }),
        },
      };
    case types.DELETE_MAJOR_UNIT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.testId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  units: {
                    ...c.data.units,
                    [payload.selectedBracket]: (c.data.units[payload.selectedBracket] || []).filter(item => item._id !== payload.unitId),
                  },
                },
              };
            }

            return c;
          }),
        },
      };
    case types.ADD_MINOR_UNIT_STATE: {
      let { units } = state.currentAnswer;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.testId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  answers: {
                    ...c.data.answers,
                    [payload.selectedBracket]: c.data.answers[payload.selectedBracket].map((answer) => {
                      if (answer._id === payload.answerId) {
                        units = uniqBy([...(answer.units || []), { _id: payload.unitId }], '_id');

                        return {
                          ...answer,
                          units,
                        };
                      }

                      return answer;
                    }),
                  },
                },
              };
            }

            return c;
          }),
        },
        currentAnswer: { ...state.currentAnswer, units },
      };
    }
    case types.DELETE_MINOR_UNIT_STATE: {
      let { units } = state.currentAnswer;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.testId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  answers: {
                    ...c.data.answers,
                    [payload.selectedBracket]: c.data.answers[payload.selectedBracket].map((answer) => {
                      if (answer._id === payload.answerId) {
                        units = (answer.units || []).filter(item => item._id !== payload.unitId);

                        return {
                          ...answer,
                          units,
                        };
                      }

                      return answer;
                    }),
                  },
                },
              };
            }

            return c;
          }),
        },
        currentAnswer: { ...state.currentAnswer, units },
      };
    }
    case types.UPDATE_ANSWER_COVER_CONNECTION_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.testId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  answers: {
                    ...c.data.answers,
                    [payload.selectedBracket]: c.data.answers[payload.selectedBracket].map((answer) => {
                      if (answer._id === payload.answerId) {
                        return {
                          ...answer,
                          units: answer.units.map(unit => ({ ...unit, [payload.property]: payload.value })),
                        };
                      }

                      return answer;
                    }),
                  },
                },
              };
            }

            return c;
          }),
        },
      };
    case types.UPDATE_TEST_COVER_CONNECTION_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.testId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  units: {
                    ...c.data.units,
                    [payload.selectedBracket]: c.data.units[payload.selectedBracket].map(unit => ({ ...unit, [payload.property]: payload.value })),
                  },
                },
              };
            }

            return c;
          }),
        },
      };
    case types.SET_CURRENT_ANSWER_STATE:
    case types.UPDATE_CURRENT_ANSWER_STATE:
      return { ...state, currentAnswer: payload };
    case types.DELETE_TEST_STATE:
    case types.DELETE_STORY_STATE:
    case types.CREATE_TEST_SUCCESS:
    case types.CREATE_STORY_SUCCESS:
      return {
        ...state,
      };
    case types.ADD_BRAINSTORM_COVER_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.storyId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  grainRequests: uniqBy([...(c.data.grainRequests || []), { _id: payload.unitId, position: payload.position }], '_id'),
                },
              };
            }

            return c;
          }),
        },
      };
    case types.DELETE_BRAINSTORM_COVER_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === payload.storyId) {
              return {
                ...c,
                data: {
                  ...c.data,
                  grainRequests: (c.data.grainRequests || []).filter(item => item._id !== payload.unitId),
                },
              };
            }

            return c;
          }),
        },
      };
    default:
      return state;
  }
}
