import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import fetchIntercept from 'fetch-intercept';

import history from './history';
import createFetch from './createFetch';
import * as serviceWorker from './serviceWorker';

import theme from './app/components/theme';
import configureStore from './app/store/configureStore';

import StudioIntlProvider from './app/translations/StudioIntlProvider';
import RootRouter from './app/routes';
import AppContext from './context';

import './styles/normalize.css';
import './styles/root.css';

if (process.env.ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://95b682c7e851482997a64a0cb74d6b11@sentry.io/1763775',
  });
}

const fetchWithDefaults = createFetch(fetch, {
  baseUrl: '', // use env var here??
});

// Initialize fetchIntercept
// eslint-disable-next-line
fetchIntercept.register({
  response: (res) => {
    if (res.status === 401) {
      const loginUrl = res.headers.get('X-LOGIN-URL');

      if (history) history.replace(`/unauthorized?login-url=${loginUrl}`);
    }

    if (res.status === 403 && history) {
      history.replace('/forbidden');
    }
    return res;
  },
});

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENABLE_WDYR === 'true') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, { collapseGroups: true });
}

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENABLE_LOGROCKET === 'true') {
  LogRocket.init('mz9dtw/studio-production');
}

const store = configureStore({}, { fetch: fetchWithDefaults });

const context = {
  websocket: {
    url: process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3005/websocket',
  },
  s3: {
    suffix: process.env.REACT_APP_S3_BUCKET_PREFIX || '',
    url: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com`,
  },
  gymglish_secret: process.env.REACT_APP_GYMGLISH_SECRET_ACCESS_KEY,
  store,
};

const App = () => (
  <StudioIntlProvider>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <AppContext.Provider value={context}>
          <RootRouter />
        </AppContext.Provider>
      </Provider>
    </MuiThemeProvider>
  </StudioIntlProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
