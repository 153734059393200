import _ from 'lodash';

import * as types from '../../../constants';
import { GRAIN } from '../../../../share';

export const initialState = {
  data: {
    covers: [],
    content: [],
    cover: {
      appearsIn: [],
      category: {},
      component: {},
      content: [],
      isSelfRequestIn: [],
      comments: [],
    },
  },
  fetchingUnits: false,
  currentAnswer: {},
  htmlPreview: null,
  status: null,
  loading: true,
  loadingAppearsIn: false,
  categories: [],
};

export default function (state = initialState, { type, payload, error }) {
  switch (type) {
    case types.FETCH_APPEARS_AS_SR_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            isSelfRequestIn: payload,
          },
        },
      };
    case types.FETCH_APPEARS_AS_HINT_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            isHintIn: payload,
          },
        },
      };
    case types.FETCH_APPEARS_IN_DESSERT_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            inDessertIn: payload,
          },
        },
      };
    case types.CREATE_COVER_TEST_REQUEST:
    case types.CREATE_UNIT_REQUEST:
    case types.DELETE_COVER_REQUEST:
    case types.DELETE_COVER_TEST_REQUEST:
    case types.FETCH_COVER_CATEGORIES_REQUEST:
    case types.UPDATE_COVER_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_COVER_CONTENT_REQUEST:
    case types.FETCH_COVER_COMPONENT_REQUEST:
    case types.UPDATE_COVER_TEST_REQUEST:
      return { ...state, loading: !payload.silent };
    case types.FETCH_COVER_APPEARS_IN_REQUEST:
      return { ...state, loadingAppearsIn: !payload.silent };
    case types.UPDATE_COVER_TEST_SUCCESS:
      return { ...state, loading: false };
    case types.FETCH_UNITS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
        },
        fetchingUnits: true,
        loading: !payload.silent,
      };
    case types.SUBMIT_UNIT_COMMENT_SUCCESS: {
      const comments = [payload.newComment, ...state.data.cover.comments];

      return {
        ...state,
        data: {
          ...state.data,
          cover: { ...state.data.cover, comments },
        },
        loading: false,
      };
    }
    case types.UPDATE_UNIT_COMMENT_SUCCESS: {
      const { data: { cover: { comments } } } = state;
      const { comment } = payload;
      const updatedComments = comments.map(oldComment => oldComment._id === comment._id
        ? { ...oldComment, done: !oldComment.done } : oldComment);
      return {
        ...state,
        data: {
          ...state.data,
          cover: { ...state.data.cover, comments: updatedComments },
        },
        loading: false,
      };
    }
    case types.FETCH_COVER_CATEGORIES_SUCCESS:
      return { ...state, categories: [...payload.categories], loading: false };
    case types.FETCH_UNITS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          covers: payload.units.covers,
        },
        fetchingUnits: false,
        loading: false,
        query: payload.units.query,
        page: parseInt(payload.units.page, 10) || 1,
        pagesTotal: parseInt(payload.units.pagesTotal, 10) || 1,
        sortBy: payload.units.sortBy,
        order: payload.units.order,
        isEmptyFilterActivated: payload.units.onlyEmpty,
      };
    }
    case types.FETCH_SINGLE_COVER_SUCCESS: {
      const { unit } = payload;

      let newCovers = [...state.data.covers];
      const existingUnitIndex = newCovers.findIndex(u => u._id === unit._id);

      if (existingUnitIndex >= 0) {
        newCovers[existingUnitIndex] = unit;
      } else {
        newCovers = [...newCovers, unit];
      }

      return {
        ...state,
        data: {
          ...state.data,
          covers: newCovers,
        },
      };
    }
    case types.FETCH_UNITS_FAILURE:
      return {
        ...state,
        fetchingUnits: false,
        loading: false,
        loadingAppearsIn: false,
        error,
        query: '',
        page: 1,
        pagesTotal: 1,
        sortBy: 'title',
        order: 'asc',
        isEmptyFilterActivated: false,
      };
    case types.FETCH_COVER_CATEGORIES_FAILURE:
    case types.FETCH_COVER_APPEARS_IN_FAILURE:
    case types.UPDATE_COVER_CATEGORY_FAILURE:
    case types.UPDATE_COVER_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingUnits: false,
        loading: false,
        loadingAppearsIn: false,
        error,
      };
    case types.UNIT_TITLE_UPDATE_SUCCESS:
      return {
        ...state,
      };
    /*
    ** the reason to update the store wirth UPDATE_COVER_CECR_REQUEST is for the
    ** UI to render correctly the text input, otherwise the delay between UPDATE_COVER_CECR_REQUEST
    ** UPDATE_COVER_CECR_SUCCESS would cause the wrong rendering on the UI side
    */
    case types.UPDATE_COVER_CECR_REQUEST:
    case types.UPDATE_COVER_CECR_SUCCESS: {
      const { updatedCover: cover } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          cover,
        },
      };
    }
    case types.UPDATE_COVER_CECR_FAILURE: {
      const { updatedCoverWithFailure: cover, errorMessage: error } = payload;
      return {
        ...state,
        error,
        data: {
          ...state.data,
          cover,
        },
      };
    }
    case types.CLEAR_COVER_COMPONENT_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: { _id: '' },
        },
      };
    case types.UPDATE_COVER_SUCCESS:
      return { ...state };
    case types.FETCH_COVER_COMPONENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            ...payload.cover,
          },
        },
        loading: false,
      };
    case types.FETCH_COVER_APPEARS_IN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            appearsIn: [...payload.appearsIn],
          },
        },
        loadingAppearsIn: false,
      };
    case types.FETCH_COVER_CONTENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: [...payload.content],
          },
        },
        loading: false,
      };
    case types.DELETE_COVER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cover: null,
        },
        loading: false,
      };
    case types.DELETE_COVER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.SET_CURRENT_ANSWER_STATE:
      return { ...state, currentAnswer: payload };
    case types.UPDATE_CURRENT_ANSWER_STATE:
      return { ...state, currentAnswer: payload };
    case types.UPDATE_TEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (payload.origin === GRAIN && c.data._id === payload.test._id) {
                return {
                  ...c,
                  data: {
                    ...c.data,
                    ...payload.test,
                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.ADD_MINOR_COVER_COVER_TEST_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (c.data._id === payload.testId) {
                return {
                  ...c,
                  data: {
                    ...c.data,
                    answers: {
                      ...c.data.answers,
                      [payload.selectedBracket]: c.data.answers[payload.selectedBracket].map((answer) => {
                        if (answer._id === payload.answerId) {
                          return {
                            ...answer,
                            units: _.uniqBy([...(answer.units || []), { _id: payload.unitId }], '_id'),
                          };
                        }

                        return answer;
                      }),
                    },
                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.DELETE_MINOR_COVER_COVER_TEST_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (c.data._id === payload.testId) {
                return {
                  ...c,
                  data: {
                    ...c.data,

                    answers: {
                      ...c.data.answers,
                      [payload.selectedBracket]: c.data.answers[payload.selectedBracket].map((answer) => {
                        if (answer._id === payload.answerId) {
                          return {
                            ...answer,
                            units: (answer.units || []).filter(item => item._id !== payload.coverId),
                          };
                        }

                        return answer;
                      }),
                    },
                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.ADD_MAJOR_COVER_COVER_TEST_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (c.data._id === payload.testId) {
                return {
                  ...c,
                  data: {
                    ...c.data,
                    units: {
                      ...c.data.units,
                      [payload.selectedBracket]: _.uniqBy([...(c.data.units[payload.selectedBracket] || []), { _id: payload.unitId }], '_id'),
                    },
                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.DELETE_MAJOR_COVER_COVER_TEST_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (c.data._id === payload.testId) {
                return {
                  ...c,
                  data: {
                    ...c.data,
                    units: {
                      ...c.data.units,
                      [payload.selectedBracket]: (c.data.units[payload.selectedBracket] || []).filter(item => item._id !== payload.coverId),
                    },
                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.UPDATE_ANSWER_COVER_TEST_CONNECTION_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (c.data._id === payload.testId) {
                return {
                  ...c,
                  data: {
                    ...c.data,
                    answers: {
                      ...c.data.answers,
                      [payload.selectedBracket]: c.data.answers[payload.selectedBracket].map((answer) => {
                        if (answer._id === payload.answerId) {
                          return {
                            ...answer,
                            units: (answer.units || []).map(unit => ({ ...unit, [payload.property]: payload.value })),
                          };
                        }

                        return answer;
                      }),
                    },

                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.UPDATE_TEST_COVER_TEST_CONNECTION_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          cover: {
            ...state.data.cover,
            content: state.data.cover.content.map((c) => {
              if (c.data._id === payload.testId) {
                return {
                  ...c,
                  data: {
                    ...c.data,
                    units: {
                      ...c.data.units,
                      [payload.selectedBracket]: (c.data.units[payload.selectedBracket] || []).map(unit => ({ ...unit, [payload.property]: payload.value })),
                    },
                  },
                };
              }

              return c;
            }),
          },
        },
      };
    case types.FETCH_PREVIEW_COVER_REQUEST:
      return {
        ...state,
        loading: true,
        htmlPreview: null,
      };
    case types.FETCH_PREVIEW_COVER_SUCCESS:
      return {
        ...state,
        loading: false,
        htmlPreview: [...payload.html],
        status: payload.status,
      };
    case types.FETCH_PREVIEW_COVER_FAILURE:
      return {
        ...state,
        error: payload.message,
        loading: false,
      };
    case types.REMOVE_PREVIEW_COVER_HTML:
      return {
        ...state,
        htmlPreview: null,
      };
    case types.UPDATE_IO_TAGS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cover: Object.assign(state.data.cover, payload.cover),
        },
      };
    default:
      return state;
  }
}
