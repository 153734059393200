import get from 'lodash/get';
import { FETCH_LOCKS_SUCCESS } from '../constants';
import { uuidv4 } from '../../util';

export default function lock(state = { locks: {}, userId: uuidv4() }, action) {
  let newState = { ...state };

  if (action.type.endsWith('_SUCCESS')) {
    const userData = get(action, 'payload.meta.user');
    if (userData) {
      newState = {
        ...newState,
        userId: userData.userId.toString(),
      };
    }
  }

  switch (action.type) {
    case FETCH_LOCKS_SUCCESS:
      return {
        ...newState,
        locks: action.payload.locks,
      };
    default:
      return newState;
  }
}
