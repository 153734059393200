import * as types from '../../../constants';
import executeAddonReducers from './addons';
import { LESSON } from '../../../../share';

export const initialLessonState = {
  data: {
    _id: '',
    title: '',
    content: [],
    comments: [],
    status: '',
  },
  currentAnswer: {},
  overview: {
    title: '',
    lessonId: '',
    stories: {},
    dessert: '',
  },
  loading: false,
  loadingHints: false,
  loadingSelfRequests: false,
  loadingRelatedLinks: false,
  loadingStory: {},
  loadingTest: {},
  html: null,
  openGrainEditor: null,
};

export default function lesson(state = initialLessonState, action) {
  const exec = executeAddonReducers(action);

  switch (action.type) {
    case types.FETCH_LESSON_DETAILS_SUCCESS: {
      return exec({
        ...state,
        data: action.payload.lesson,
        redirectUrl: action.payload.redirectUrl,
        loading: false,
        loadingHints: false,
        loadingSelfRequests: false,
      });
    }
    case types.LESSON_RELATED_LINKS_UPDATE_SUCCESS:
      return exec({
        ...state,
        data: {
          ...state.data,
          relatedLinks: [...action.payload.relatedLinks],
        },
        loadingRelatedLinks: false,
        loading: false,
      });
    case types.LESSON_RELATED_LINKS_UPDATE_REQUEST:
      return exec({
        ...state,
        loadingRelatedLinks: true,
        loading: true,
      });
    case types.LESSON_RELATED_LINKS_UPDATE_FAILURE:
      return exec({
        ...state,
        loadingRelatedLinks: false,
        loading: false,
      });
    case types.SUBMIT_LESSON_COMMENT_SUCCESS: {
      return exec({
        ...state,
        data: {
          ...state.data,
          comments: [action.payload.newComment, ...state.data.comments],
        },
        loading: false,
      });
    }
    case types.UPDATE_LESSON_COMMENT_SUCCESS: {
      const { data: { comments } } = state;
      const { payload: { comment } } = action;
      const updatedComments = comments.map(oldComment => oldComment._id === comment._id
        ? { ...oldComment, done: !oldComment.done } : oldComment);
      return exec({
        ...state,
        data: {
          ...state.data,
          comments: updatedComments,
        },
        loading: false,
      });
    }
    case types.LESSON_TITLE_UPDATE_SUCCESS:
      return exec({
        ...state,
        data: {
          ...state.data,
          title: action.payload.title,
        },
        loading: false,
      });
    case types.REMOVE_THEME_REQUEST:
      return exec({
        ...state,
        data: {
          ...state.data,
          provides: state.data.provides.filter(themeId => themeId !== action.payload._id),
        },
      });
    case types.UPDATE_TEST_SUCCESS: {
      const { payload } = action;

      return exec({
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (payload.origin === LESSON && c.data._id === payload.test._id) {
              return {
                ...c,
                data: {
                  ...c.data,
                  ...payload.test,
                },
              };
            }
            return c;
          }),
        },
      });
    }
    case types.UPDATE_STORY_STATE: {
      return exec({
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((c) => {
            if (c.data._id === action.payload._id) {
              return {
                ...c,
                data: {
                  ...action.payload,
                },
              };
            }
            return c;
          }),
        },
      });
    }
    case types.REORDER_UPDATE_CONTENT_ORDER:
      return exec({
        ...state,
        data: {
          ...state.data,
          content: action.payload.contentIds.map((id, index) => {
            return { ...state.data.content.find(c => c.data._id === id), order: index + 1 };
          }),
        },
      });
    case types.FETCH_LESSON_OVERVIEW_SUCCESS:
      return exec({
        ...state,
        overview: { ...action.payload.lesson },
        loading: false,
      });
    case types.FETCH_LESSON_COMMENT_SUCCESS:
      return exec({
        ...state,
        data: {
          ...state.data,
          comments: action.payload.comments,
        },
        loading: false,
      });
    case types.OPEN_GRAIN_EDITOR:
      return exec({
        ...state,
        openGrainEditor: action.id,
      });
    default:
      return exec(state);
  }
}
