import React from 'react';
import PropTypes from 'prop-types';

import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';

import 'normalize.css';
import cn from 'classnames';
import s from './Layout.module.pcss';

import Header from '../Header';

export function LayoutComponent({ notifications, displayHeader, children }) {
  return (
    <div>
      {displayHeader && <Header />}
      <Notifications notifications={notifications} />
      <div className={cn(s.container)}>
        {children}
      </div>
    </div>
  );
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape()),
  displayHeader: PropTypes.bool,
};

LayoutComponent.defaultProps = {
  notifications: [],
  displayHeader: false,
};

const mapState = (state) => {
  return ({
    notifications: state.notifications,
  });
};

export default connect(mapState)(LayoutComponent);
