
import React, { lazy, Suspense } from 'react';
import { Route, Switch, Router as BrowserRouter } from 'react-router-dom';

import Layout from '../components/common/Layout';
import LoadingOverlay from '../components/common/LoadingOverlay';
import history from '../../history';

const ErrorNotification = lazy(() => import('../components/common/ErrorNotification'));
const Home = lazy(() => import('./home'));
const Login = lazy(() => import('./login'));
const Logout = lazy(() => import('./logout'));
const ErrorPage = lazy(() => import('./error'));
const NotFound = lazy(() => import('./not-found'));
const Unauthorized = lazy(() => import('./unauthorized'));
const Forbidden = lazy(() => import('./forbidden'));
const ProjectPage = lazy(() => import('../scenes/project'));
const CoursePage = lazy(() => import('../scenes/course'));
const LessonEditor = lazy(() => import('../scenes/lesson-editor'));
const CoverList = lazy(() => import('../scenes/cover-list'));
const UnitPage = lazy(() => import('../scenes/unit'));
const LessonPreview = lazy(() => import('../scenes/lesson-preview'));
const CoverPreview = lazy(() => import('../scenes/cover-preview/CoverPreview'));

const notifyErrors = Component => props => (
  <ErrorNotification {...props}>
    <Component {...props} />
  </ErrorNotification>
);

const lazyLoad = Component => props => (
  <Suspense fallback={<LoadingOverlay />}>
    <Component {...props} />
  </Suspense>
);

const RootRouter = () => (
  <BrowserRouter history={history}>
    <Layout displayHeader>
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          <Route exact path="/" component={lazyLoad(notifyErrors(Home))} />
          <Route exact path="/:projectHash([a-zA-Z\d]{1,7})/courses" component={lazyLoad(notifyErrors(ProjectPage))} />
          <Route exact path="/:projectHash([a-zA-Z\d]{1,7})/courses/:courseId([a-fA-F\d]{24})" component={lazyLoad(notifyErrors(CoursePage))} />
          <Route
            exact
            path="/:projectHash([a-zA-Z\d]{1,7})/courses/:courseId([a-fA-F\d]{24})/lessons/:lessonId([a-fA-F\d]{24})/edit"
            component={lazyLoad(notifyErrors(LessonEditor))}
          />
          <Route
            exact
            path="/:projectHash([a-zA-Z\d]{1,7})/courses/:courseId([a-fA-F\d]{24})/lessons/:lessonIds([a-fA-F\d]{24})+/preview"
            component={lazyLoad(notifyErrors(LessonPreview))}
          />
          <Route exact path="/:projectHash([a-zA-Z\d]{1,7})/grains" component={lazyLoad(notifyErrors(CoverList))} />
          <Route exact path="/:projectHash([a-zA-Z\d]{1,7})/grains/edit/:grainId([a-fA-F\d]{24})?" component={lazyLoad(notifyErrors(UnitPage))} />
          <Route exact path="/:projectHash([a-zA-Z\d]{1,7})/grains/:grainIds([a-fA-F\d]{24})+/preview" component={lazyLoad(notifyErrors(CoverPreview))} />
          <Route exact path="/auth/login" component={lazyLoad(notifyErrors(Login))} />
          <Route exact path="/auth/logout" component={lazyLoad(notifyErrors(Logout))} />
          <Route exact path="/error" component={lazyLoad(notifyErrors(ErrorPage))} />
          <Route exact path="/unauthorized" component={lazyLoad(notifyErrors(Unauthorized))} />
          <Route exact path="/forbidden" component={lazyLoad(notifyErrors(Forbidden))} />
          <Route component={lazyLoad(notifyErrors(NotFound))} />
        </Switch>
      </Suspense>
    </Layout>
  </BrowserRouter>
);

export default RootRouter;
