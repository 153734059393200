import get from 'lodash/get';
import * as types from '../../../constants';

export const initialCourseState = {
  _id: 'someCourseId',
  name: '',
  description: '',
  image: '',
  project: '',
  content: [],
  lessonCount: 0,
  seriesCount: 0,
  page: 1,
  pageCount: 1,
  lessonMarkedForDeletion: [],
  meta: {
    _id: '',
    name: '',
  },
  loading: false,
};

export default function (state = initialCourseState, { type, payload }) {
  switch (type) {
    case types.CREATE_LESSON_REQUEST:
    case types.CREATE_SERIES_REQUEST:
    case types.DUPLICATE_LESSON_REQUEST:
    case types.REORDER_COURSE_CONTENT_REQUEST:
    case types.SYNC_COURSE_REQUEST:
    case types.FETCH_COURSE_LESSONS_REQUEST:
    case types.FETCH_COURSE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_COURSE_DETAILS_SUCCESS: {
      // This is to avoid flashing the old course content
      // before updating to the new course content.
      if (get(payload, 'course.content', undefined)) {
        // eslint-disable-next-line no-param-reassign
        delete payload.course.content;
      }

      return {
        ...state,
        ...payload.course,
        meta: {
          _id: get(payload, 'course._id', ''),
          name: get(payload, 'course.name', ''),
        },
        loading: false,
      };
    }
    case types.FETCH_COURSE_LESSONS_SUCCESS: {
      const { meta, content, ...rest } = payload;

      return {
        ...state,
        ...rest,
        meta: { ...meta.course },
        content,
        loading: false,
      };
    }
    case types.FETCH_COURSE_LESSON_GRAINS_SUCCESS: {
      const { lessonId, ...rest } = payload;
      const { content } = state;
      const newContent = content.map((item) => {
        if (item.type === 'lesson' && item._id === lessonId) {
          return ({ ...item, ...rest });
        }
        if (item.type === 'lesson') {
          return ({ ...item });
        }
        const seriesLessons = item.lessons.map(lesson => lesson._id === lessonId ? { ...lesson, ...rest } : { ...lesson });
        return ({ ...item, lessons: seriesLessons });
      });

      return {
        ...state,
        content: newContent,
        loading: false,
      };
    }
    case types.FETCH_COURSE_LESSON_GRAINS_FAILURE:
      return { ...state, loading: false };
    case types.CREATE_SERIES_SUCCESS:
    case types.CREATE_SERIES_FAILURE:
    case types.CREATE_LESSON_SUCCESS:
    case types.CREATE_LESSON_FAILURE:
    case types.REORDER_COURSE_CONTENT_SUCCESS:
    case types.REORDER_COURSE_CONTENT_FAILURE:
      return { ...state, loading: false };
    case types.FETCH_LESSON_DETAILS_SUCCESS:
    case types.FETCH_LESSON_OVERVIEW_SUCCESS:
    case types.SUBMIT_LESSON_COMMENT_SUCCESS:
    case types.FETCH_LESSON_COMMENT_SUCCESS:
    case types.UPDATE_LESSON_COMMENT_SUCCESS:
      return {
        ...state,
        meta: { ...payload.meta.course },
      };
    case types.LESSON_LEARNING_ORDER_UPDATE_SUCCESS: {
      const { learningOrder, lessonId } = payload;
      const updatedContent = state.content.map((item) => {
        if (item.type === 'lesson') {
          return (lessonId === item._id ? { ...item, learningOrder } : item);
        }
        const updatedLessons = item.lessons.map(lesson => lessonId === lesson._id ? { ...lesson, learningOrder } : lesson);
        return {
          ...item,
          lessons: updatedLessons,
        };
      });
      return {
        ...state,
        content: updatedContent,
        loading: false,
      };
    }
    case types.DELETE_LESSON_REQUEST:
      return {
        ...state,
        loading: true,
        lessonMarkedForDeletion: [...state.lessonMarkedForDeletion, payload],
      };
    case types.DELETE_LESSON_FAILURE:
    case types.DELETE_LESSON_ABORTED:
    case types.DELETE_LESSON_SUCCESS: {
      const newLessonMarkedForDeletion = [...state.lessonMarkedForDeletion];
      const lessonToRemoveIndex = newLessonMarkedForDeletion.indexOf(payload);

      if (lessonToRemoveIndex >= 0) {
        newLessonMarkedForDeletion.splice(lessonToRemoveIndex, 1);
      }

      return {
        ...state,
        lessonMarkedForDeletion: newLessonMarkedForDeletion,
        loading: false,
      };
    }
    default:
      return state;
  }
}
