import * as types from '../../../constants';

export const initialState = {
  data: { dessert: null },
};

export default function (state = initialState, { type, payload, error }) {
  switch (type) {
    case types.CREATE_DESSERT_REQUEST:
      return {
        ...state,
        creatingDessert: true,
      };
    case types.CREATE_DESSERT_SUCCESS:
      return {
        ...state,
        creatingDessert: false,
      };
    case types.CREATE_DESSERT_FAILURE:
      return {
        ...state,
        creatingDessert: false,
        error,
      };
    case types.FETCH_DESSERT_REQUEST:
      return {
        ...state,
        fetchingDessert: true,
      };
    case types.FETCH_DESSERT_SUCCESS:
      return {
        ...state,
        data: { dessert: payload.dessert },
        fetchingDessert: false,
      };
    case types.FETCH_DESSERT_FAILURE:
      return {
        ...state,
        fetchingDessert: false,
        error,
      };
    case types.DELETE_DESSERT_REQUEST:
      return {
        ...state,
        deletingDessert: true,
      };
    case types.DELETE_DESSERT_SUCCESS:
      return {
        ...state,
        deletingDessert: false,
        data: { dessert: null },
      };
    case types.DELETE_DESSERT_FAILURE:
      return {
        ...state,
        deletingDessert: false,
      };
    case types.SYNC_DESSERT_REQUEST:
      return {
        ...state,
        updatingDessert: true,
      };
    case types.SYNC_DESSERT_SUCCESS:
      return {
        ...state,
        updatingDessert: false,
      };
    case types.SYNC_DESSERT_FAILURE:
      return {
        ...state,
        updatingDessert: false,
      };
    case types.UPDATE_DESSERT_STATE:
      return {
        ...state,
        data: { dessert: payload },
      };
    default:
      return state;
  }
}
