/* eslint-disable global-require */
// import Storage from 'dom-storage';

function storage() {
  // if (typeof localStorage === 'undefined' || localStorage === null) {
  //   return new Storage(null, { strict: true });
  // }
  return localStorage;
}

export default storage();
