import get from 'lodash/get';

const INITIAL_STATE = {
  user: {},
};

export default function (state = INITIAL_STATE, action) {
  if (action.type.endsWith('_SUCCESS')) {
    const userData = get(action, 'payload.meta.user');
    if (userData) {
      return {
        user: { ...userData },
      };
    }
  }

  return state;
}
