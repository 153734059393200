import {
  FETCH_COURSE_ACTORS_REQUEST,
  FETCH_COURSE_ACTORS_SUCCESS,
  FETCH_COURSE_ACTORS_FAILURE,
  UPDATE_COURSE_ACTORS_SUCCESS,
} from '../../../constants';

export const initialState = {
  actors: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_COURSE_ACTORS_SUCCESS:
    case UPDATE_COURSE_ACTORS_SUCCESS:
      return payload.actors;
    case FETCH_COURSE_ACTORS_REQUEST:
      return [];
    case FETCH_COURSE_ACTORS_FAILURE:
      return [];
    default:
      return state;
  }
}
