import * as types from '../../../constants';

export const initialProjectState = {
  meta: {},
  projects: [],
  courses: [],
  themes: [],
  page: 1,
  pageCount: 1,
  loading: false,
};

export default function course(state = initialProjectState, { type, payload }) {
  switch (type) {
    case types.FETCH_PROJECTS_REQUEST:
    case types.FETCH_COURSES_REQUEST:
    case types.SAVE_PROJECT_REQUEST:
    case types.SAVE_COURSE_REQUEST:
    case types.DELETE_COURSE_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_PROJECTS_SUCCESS:
      return { ...state, projects: payload.projects, loading: false };
    case types.SAVE_COURSE_SUCCESS:
    case types.SAVE_COURSE_FAILURE:
    case types.SAVE_PROJECT_SUCCESS:
    case types.SAVE_PROJECT_FAILURE:
      return { ...state, loading: false };
    case types.FETCH_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload.courses,
        page: payload.page,
        pageCount: payload.pageCount,
        meta: { ...payload.meta.project },
        loading: false,
      };
    case types.FETCH_UNITS_SUCCESS:
    case types.FETCH_UNITS_LIST_SUCCESS:
      return {
        ...state,
        meta: { ...payload.units.meta.project },
        loading: false,
      };
    case types.FETCH_COURSE_DETAILS_SUCCESS:
    case types.FETCH_SINGLE_UNIT_SUCCESS:
      return {
        ...state,
        meta: { ...payload.meta.project },
        loading: false,
      };
    case types.FETCH_COURSE_LESSONS_SUCCESS:
    case types.FETCH_COVER_CONTENT_SUCCESS:
    case types.FETCH_COVER_CATEGORIES_SUCCESS:
    case types.FETCH_LESSON_DETAILS_SUCCESS:
    case types.FETCH_LESSON_OVERVIEW_SUCCESS:
    case types.SUBMIT_LESSON_COMMENT_SUCCESS:
    case types.FETCH_LESSON_COMMENT_SUCCESS:
    case types.UPDATE_LESSON_COMMENT_SUCCESS:
      return {
        ...state,
        meta: { ...payload.meta.project },
        loading: false,
      };
    case types.FETCH_COVER_COMPONENT_SUCCESS:
      return {
        ...state,
        meta: { ...payload.cover.meta.project },
        loading: false,
      };
    case types.FETCH_THEMES_SUCCESS:
      return {
        ...state,
        meta: { ...payload.meta.project },
        themes: [...payload.provides],
      };
    default:
      return state;
  }
}
