import * as types from '../../../../constants';

export default function (state, { type, payload, error }) {
  switch (type) {
    case types.FETCH_PREVIEW_LESSON_REQUEST:
      return {
        ...state,
        loading: true,
        preview: null,
        previewMeta: [],
        selectedLessonId: null,
      };
    case types.FETCH_PREVIEW_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        status: payload.status,
        preview: [...payload.preview],
        previewMeta: payload.previewMeta,
        selectedLessonId: payload.selectedLessonId,
      };
    case types.FETCH_PREVIEW_LESSON_FAILURE:
      return {
        ...state,
        loading: false,
        error,
        previewMeta: [],
        selectedLessonId: null,
        status: null,
      };
    case types.FETCH_PREVIEW_CORRECTION_REQUEST:
      return {
        ...state,
        correctionPreview: null,
      };
    case types.FETCH_PREVIEW_CORRECTION_SUCCESS:
      return {
        ...state,
        status: payload.status,
        correctionPreview: [...payload.correctionPreview],
      };
    case types.FETCH_PREVIEW_CORRECTION_FAILURE:
      return {
        ...state,
        error,
        status: null,
      };
    case types.REMOVE_PREVIEW_LESSON_HTML:
      return {
        ...state,
        preview: null,
        previewMeta: [],
        selectedLessonId: null,
      };
    default:
      return state;
  }
}
