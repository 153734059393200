import * as types from '../../../../constants';

/*
* undo: function with latest createUndo
* undoAvailable: boolean indicating if there's an action available
*/

export default function (state, { type, payload }) {
  switch (type) {
    case types.REGISTER_UNDO: {
      return {
        ...state,
        undo: payload,
        undoAvailable: true,
      };
    }
    case types.EXECUTE_UNDO: {
      return {
        ...state,
        undo: () => {},
        undoAvailable: false,
      };
    }
    default:
      return state;
  }
}
