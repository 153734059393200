import React from 'react';

const AppContext = React.createContext();

export const withContext = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <AppContext.Consumer>
          {context => <WrappedComponent {...this.props} context={context} />}
        </AppContext.Consumer>
      );
    }
  };
};

export default AppContext;
