import * as types from '../../../../constants';

export default function (state, { type, payload }) {
  switch (type) {
    case types.SYNC_DESSERT_REQUEST:
    case types.CREATE_DESSERT_REQUEST:
    case types.CREATE_TEST_REQUEST:
    case types.CREATE_STORY_REQUEST:
    case types.CREATE_UNIT_REQUEST:
    case types.DELETE_DESSERT_REQUEST:
    case types.DELETE_STORY_REQUEST:
    case types.DELETE_TEST_REQUEST:
    case types.FETCH_LESSON_ITEM_REQUEST:
    case types.FETCH_UNITS_LIST_REQUEST:
    case types.LESSON_STATUS_UPDATE_REQUEST:
    case types.LESSON_TITLE_UPDATE_REQUEST:
    case types.REORDER_LESSON_CONTENT_REQUEST:
    case types.LESSON_TUTORIAL_UPDATE_REQUEST:
    case types.FETCH_THEMES_REQUEST:
    case types.ADD_THEME_REQUEST:
    case types.REMOVE_THEME_REQUEST:
    case types.CREATE_THEME_REQUEST:
    case types.FETCH_SINGLE_UNIT_REQUEST:
      return { ...state, loading: true };
    case types.UPDATE_STORY_HINTS:
      return { ...state, loadingHints: true };
    case types.FETCH_LESSON_DETAILS_REQUEST:
      return { ...state, loading: true, loadingSelfRequests: true };
    case types.UPDATE_STORY_STATE:
      return { ...state, loadingStory: { ...state.loadingStory, [payload._id]: true } };
    case types.ADD_ADVANCED_TEXT_REQUEST:
    case types.REMOVE_ADVANCED_TEXT_REQUEST:
    case types.UPDATE_ADVANCED_TEXT_REQUEST:
    case types.WIPEOUT_ADVANCED_TEXT_REQUEST:
    case types.ADD_ANSWER_SET_REQUEST:
    case types.ADD_ANSWER_REQUEST:
    case types.REMOVE_ANSWER_SET_REQUEST:
    case types.REMOVE_ANSWER_REQUEST:
    case types.UPDATE_ANSWER_REQUEST:
    case types.REORDER_ANSWER_REQUEST:
    case types.UPDATE_TEST_REQUEST: {
      return { ...state, loadingTest: { ...state.loadingTest, [payload._id]: true } };
    }
    case types.UPDATE_TEST_SUCCESS: {
      return { ...state, loadingTest: { ...state.loadingTest, [payload.test._id]: false } };
    }
    case types.SUBMIT_LESSON_COMMENT_REQUEST:
    case types.FETCH_LESSON_COMMENT_REQUEST:
    case types.UPDATE_LESSON_COMMENT_REQUEST:
      return { ...state, loading: true, loadingComments: true };
    case types.LESSON_STATUS_UPDATE_SUCCESS: {
      const newState = { ...state, loading: false };
      newState.data.status = payload.status || '';
      return newState;
    }
    case types.SYNC_DESSERT_SUCCESS:
    case types.LESSON_TITLE_UPDATE_SUCCESS:
    case types.LESSON_TITLE_UPDATE_FAILURE:
    case types.LESSON_STATUS_UPDATE_FAILURE:
    case types.CREATE_DESSERT_SUCCESS:
    case types.CREATE_TEST_SUCCESS:
    case types.CREATE_STORY_SUCCESS:
    case types.CREATE_UNIT_SUCCESS:
    case types.DELETE_DESSERT_SUCCESS:
    case types.DELETE_STORY_SUCCESS:
    case types.DELETE_TEST_SUCCESS:
    case types.FETCH_UNITS_LIST_SUCCESS:
    case types.REORDER_LESSON_CONTENT_SUCCESS:
    case types.LESSON_TUTORIAL_UPDATE_SUCCESS:
    case types.ADD_THEME_SUCCESS:
    case types.ADD_THEME_FAILURE:
    case types.REMOVE_THEME_SUCCESS:
    case types.REMOVE_THEME_FAILURE:
    case types.CREATE_THEME_SUCCESS:
    case types.CREATE_THEME_FAILURE:
    case types.SYNC_DESSERT_FAILURE:
    case types.CREATE_DESSERT_FAILURE:
    case types.CREATE_TEST_FAILURE:
    case types.CREATE_STORY_FAILURE:
    case types.CREATE_UNIT_FAILURE:
    case types.DELETE_DESSERT_FAILURE:
    case types.DELETE_STORY_FAILURE:
    case types.DELETE_TEST_FAILURE:
    case types.FETCH_UNITS_LIST_FAILURE:
    case types.REORDER_LESSON_CONTENT_FAILURE:
    case types.LESSON_TUTORIAL_UPDATE_FAILURE:
    case types.FETCH_SINGLE_UNIT_SUCCESS:
    case types.FETCH_SINGLE_UNIT_FAILURE:
      return { ...state, loading: false };
    case types.ADD_ADVANCED_TEXT_SUCCESS:
    case types.REMOVE_ADVANCED_TEXT_SUCCESS:
    case types.UPDATE_ADVANCED_TEXT_SUCCESS:
    case types.WIPEOUT_ADVANCED_TEXT_SUCCESS:
    case types.ADD_ANSWER_SET_SUCCESS:
    case types.ADD_ANSWER_SUCCESS:
    case types.REMOVE_ANSWER_SET_SUCCESS:
    case types.REMOVE_ANSWER_SUCCESS:
    case types.UPDATE_ANSWER_SUCCESS:
    case types.REORDER_ANSWER_SUCCESS:
    case types.ADD_ADVANCED_TEXT_FAILURE:
    case types.REMOVE_ADVANCED_TEXT_FAILURE:
    case types.UPDATE_ADVANCED_TEXT_FAILURE:
    case types.WIPEOUT_ADVANCED_TEXT_FAILURE:
    case types.ADD_ANSWER_SET_FAILURE:
    case types.ADD_ANSWER_FAILURE:
    case types.REMOVE_ANSWER_SET_FAILURE:
    case types.REMOVE_ANSWER_FAILURE:
    case types.UPDATE_ANSWER_FAILURE:
    case types.REORDER_ANSWER_FAILURE: {
      return { ...state, loadingTest: { ...state.loadingTest, [payload.testId]: false } };
    }
    case types.FETCH_LESSON_ITEM_SUCCESS: {
      const newState = { ...state, loading: false };

      if (payload.contentItem.type === 'story') {
        newState.loadingStory = { ...state.loadingStory, [payload.contentItem.data._id]: false };
      }

      if (payload.contentItem.type === 'test') {
        newState.loadingTest = { ...state.loadingTest, [payload.contentItem.data._id]: false };
      }

      return newState;
    }
    case types.FETCH_LESSON_ITEM_FAILURE:
    case types.UPDATE_TEST_FAILURE: {
      const { contentId } = payload;
      const newState = { ...state, loading: false };

      if (contentId) {
        if (state.loadingStory[contentId]) {
          newState.loadingStory = { ...state.loadingStory, [contentId]: false };
        }

        if (state.loadingTest[contentId]) {
          newState.loadingTest = { ...state.loadingTest, [contentId]: false };
        }
      }

      return newState;
    }
    case types.FETCH_LESSON_DETAILS_SUCCESS:
    case types.FETCH_LESSON_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingSelfRequests: false,
      };
    }
    case types.SUBMIT_LESSON_COMMENT_SUCCESS:
    case types.SUBMIT_LESSON_COMMENT_FAILURE:
    case types.FETCH_LESSON_COMMENT_SUCCESS:
    case types.FETCH_LESSON_COMMENT_FAILURE:
    case types.UPDATE_LESSON_COMMENT_SUCCESS:
    case types.UPDATE_LESSON_COMMENT_FAILURE:
      return { ...state, loading: false, loadingComments: false };
    default:
      return state;
  }
}
