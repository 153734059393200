import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
import runtime from './runtime';
import lock from './lock';
import auth from './auth';
import errors from './errors';
import project from '../scenes/project/reducers/ProjectReducer';
import course from '../scenes/course/reducers/CourseReducer';
import lesson from '../scenes/lesson-editor/reducers/LessonReducer';
import dessert from '../scenes/lesson-editor/reducers/DessertReducer';
import actors from '../scenes/lesson-editor/reducers/ActorSuggestionsReducer';
import unit from '../scenes/unit/reducers/UnitReducer';

export default combineReducers({
  notifications,
  errors,
  runtime,
  lock,
  form: reduxFormReducer,
  project,
  course,
  lesson,
  dessert,
  actors,
  unit,
  auth,
});
