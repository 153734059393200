import React from 'react';
import PropTypes from 'prop-types';
import history from '../../../../history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function Link({
  onClick,
  toNewTab,
  to,
  children,
  ...attrs
}) {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();

    if (toNewTab) {
      window.open(to, '_blank');
    } else {
      history.push(to);
    }
  };

  return <a href={to} {...attrs} onClick={handleClick}>{children}</a>;
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  toNewTab: PropTypes.bool,
};

Link.defaultProps = {
  to: '',
  onClick: null,
  toNewTab: false,
};

export default Link;
