export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';

export const FETCH_COURSE_DETAILS_REQUEST = 'FETCH_COURSE_DETAILS_REQUEST';
export const FETCH_COURSE_DETAILS_SUCCESS = 'FETCH_COURSE_DETAILS_SUCCESS';
export const FETCH_COURSE_DETAILS_FAILURE = 'FETCH_COURSE_DETAILS_FAILURE';

export const FETCH_COURSE_LESSONS_REQUEST = 'FETCH_COURSE_LESSONS_REQUEST';
export const FETCH_COURSE_LESSONS_SUCCESS = 'FETCH_COURSE_LESSONS_SUCCESS';
export const FETCH_COURSE_LESSONS_FAILURE = 'FETCH_COURSE_LESSONS_FAILURE';

export const FETCH_COURSE_LESSON_GRAINS_REQUEST = 'FETCH_COURSE_LESSON_GRAINS_REQUEST';
export const FETCH_COURSE_LESSON_GRAINS_SUCCESS = 'FETCH_COURSE_LESSON_GRAINS_SUCCESS';
export const FETCH_COURSE_LESSON_GRAINS_FAILURE = 'FETCH_COURSE_LESSON_GRAINS_FAILURE';

export const FETCH_COURSE_ACTORS_REQUEST = 'FETCH_COURSE_ACTORS_REQUEST';
export const FETCH_COURSE_ACTORS_SUCCESS = 'FETCH_COURSE_ACTORS_SUCCESS';
export const FETCH_COURSE_ACTORS_FAILURE = 'FETCH_COURSE_ACTORS_FAILURE';

export const UPDATE_COURSE_ACTORS_REQUEST = 'UPDATE_COURSE_ACTORS_REQUEST';
export const UPDATE_COURSE_ACTORS_SUCCESS = 'UPDATE_COURSE_ACTORS_SUCCESS';
export const UPDATE_COURSE_ACTORS_FAILURE = 'UPDATE_COURSE_ACTORS_FAILURE';

export const REORDER_COURSE_CONTENT_REQUEST = 'REORDER_COURSE_CONTENT_REQUEST';
export const REORDER_COURSE_CONTENT_SUCCESS = 'REORDER_COURSE_CONTENT_SUCCESS';
export const REORDER_COURSE_CONTENT_FAILURE = 'REORDER_COURSE_CONTENT_FAILURE';

export const FETCH_LESSON_DETAILS_REQUEST = 'FETCH_LESSON_DETAILS_REQUEST';
export const FETCH_LESSON_DETAILS_SUCCESS = 'FETCH_LESSON_DETAILS_SUCCESS';
export const FETCH_LESSON_DETAILS_FAILURE = 'FETCH_LESSON_DETAILS_FAILURE';

export const FETCH_LESSON_ITEM_REQUEST = 'FETCH_LESSON_ITEM_REQUEST';
export const FETCH_LESSON_ITEM_SUCCESS = 'FETCH_LESSON_ITEM_SUCCESS';
export const FETCH_LESSON_ITEM_FAILURE = 'FETCH_LESSON_ITEM_FAILURE';

export const DUPLICATE_LESSON_REQUEST = 'DUPLICATE_LESSON_REQUEST';
export const DUPLICATE_LESSON_SUCCESS = 'DUPLICATE_LESSON_SUCCESS';
export const DUPLICATE_LESSON_FAILURE = 'DUPLICATE_LESSON_FAILURE';

export const EDIT_LESSON = 'EDIT_LESSON';
export const LESSON_TITLE_UPDATE = 'LESSON_TITLE_UPDATE';
export const LESSON_TITLE_STATE_UPDATE = 'LESSON_TITLE_STATE_UPDATE';

export const LESSON_TITLE_UPDATE_REQUEST = 'LESSON_TITLE_UPDATE_REQUEST';
export const LESSON_TITLE_UPDATE_SUCCESS = 'LESSON_TITLE_UPDATE_SUCCESS';
export const LESSON_TITLE_UPDATE_FAILURE = 'LESSON_TITLE_UPDATE_FAILURE';

export const LESSON_LEARNING_ORDER_UPDATE_REQUEST = 'LESSON_LEARNING_ORDER_UPDATE_REQUEST';
export const LESSON_LEARNING_ORDER_UPDATE_SUCCESS = 'LESSON_LEARNING_ORDER_UPDATE_SUCCESS';
export const LESSON_LEARNING_ORDER_UPDATE_FAILURE = 'LESSON_LEARNING_ORDER_UPDATE_FAILURE';

export const LESSON_STATUS_UPDATE_REQUEST = 'LESSON_STATUS_UPDATE_REQUEST';
export const LESSON_STATUS_UPDATE_SUCCESS = 'LESSON_STATUS_UPDATE_SUCCESS';
export const LESSON_STATUS_UPDATE_FAILURE = 'LESSON_STATUS_UPDATE_FAILURE';

export const LESSON_TUTORIAL_UPDATE_REQUEST = 'LESSON_TUTORIAL_UPDATE_REQUEST';
export const LESSON_TUTORIAL_UPDATE_SUCCESS = 'LESSON_TUTORIAL_UPDATE_SUCCESS';
export const LESSON_TUTORIAL_UPDATE_FAILURE = 'LESSON_TUTORIAL_UPDATE_FAILURE';

export const LESSON_ILLUSTRATION_UPDATE_REQUEST = 'LESSON_ILLUSTRATION_UPDATE_REQUEST';
export const LESSON_ILLUSTRATION_UPDATE_SUCCESS = 'LESSON_ILLUSTRATION_UPDATE_SUCCESS';
export const LESSON_ILLUSTRATION_UPDATE_FAILURE = 'LESSON_ILLUSTRATION_UPDATE_FAILURE';

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAILURE = 'CREATE_COURSE_FAILURE';

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILURE = 'DELETE_COURSE_FAILURE';

export const SAVE_PROJECT_REQUEST = 'SAVE_PROJECTS_REQUEST';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECTS_SUCCESS';
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECTS_FAILURE';

export const SAVE_COURSE_REQUEST = 'SAVE_COURSE_REQUEST';
export const SAVE_COURSE_SUCCESS = 'SAVE_COURSE_SUCCESS';
export const SAVE_COURSE_FAILURE = 'SAVE_COURSE_FAILURE';

export const SYNC_COURSE_REQUEST = 'SYNC_COURSE_REQUEST';
export const SYNC_COURSE_SUCCESS = 'SYNC_COURSE_SUCCESS';
export const SYNC_COURSE_FAILURE = 'SYNC_COURSE_FAILURE';

export const CREATE_SERIES_REQUEST = 'CREATE_SERIES_REQUEST';
export const CREATE_SERIES_SUCCESS = 'CREATE_SERIES_SUCCESS';
export const CREATE_SERIES_FAILURE = 'CREATE_SERIES_FAILURE';

export const DELETE_SERIES_REQUEST = 'DELETE_SERIES_REQUEST';
export const DELETE_SERIES_SUCCESS = 'DELETE_SERIES_SUCCESS';
export const DELETE_SERIES_FAILURE = 'DELETE_SERIES_FAILURE';

export const UPDATE_SERIES_REQUEST = 'UPDATE_SERIES_REQUEST';
export const UPDATE_SERIES_SUCCESS = 'UPDATE_SERIES_SUCCESS';
export const UPDATE_SERIES_FAILURE = 'UPDATE_SERIES_FAILURE';

export const CREATE_LESSON_REQUEST = 'CREATE_LESSON_REQUEST';
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS';
export const CREATE_LESSON_FAILURE = 'CREATE_LESSON_FAILURE';

export const REORDER_LESSON_CONTENT_REQUEST = 'REORDER_LESSON_CONTENT_REQUEST';
export const REORDER_LESSON_CONTENT_SUCCESS = 'REORDER_LESSON_CONTENT_SUCCESS';
export const REORDER_LESSON_CONTENT_FAILURE = 'REORDER_LESSON_CONTENT_FAILURE';

export const REORDER_UPDATE_CONTENT_ORDER = 'REORDER_UPDATE_CONTENT_ORDER';

export const CREATE_LESSON_STATE = 'CREATE_LESSON_STATE';
export const DELETE_LESSON = 'DELETE_LESSON';
export const DELETE_LESSON_STATE = 'DELETE_LESSON_STATE';
export const DELETE_LESSON_REQUEST = 'DELETE_LESSON_REQUEST';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_FAILURE = 'DELETE_LESSON_FAILURE';
export const DELETE_LESSON_ABORTED = 'DELETE_LESSON_ABORTED';

export const SYNC_STORY_REQUEST = 'SYNC_STORY_REQUEST';
export const SYNC_STORY_SUCCESS = 'SYNC_STORY_SUCCESS';
export const SYNC_STORY_FAILURE = 'SYNC_STORY_FAILURE';

export const CREATE_STORY_REQUEST = 'CREATE_STORY_REQUEST';
export const CREATE_STORY_SUCCESS = 'CREATE_STORY_SUCCESS';
export const CREATE_STORY_FAILURE = 'CREATE_STORY_FAILURE';

export const FETCH_DESSERT_REQUEST = 'FETCH_DESSERT_REQUEST';
export const FETCH_DESSERT_SUCCESS = 'FETCH_DESSERT_SUCCESS';
export const FETCH_DESSERT_FAILURE = 'FETCH_DESSERT_FAILURE';

export const CREATE_DESSERT_REQUEST = 'CREATE_DESSERT_REQUEST';
export const CREATE_DESSERT_SUCCESS = 'CREATE_DESSERT_SUCCESS';
export const CREATE_DESSERT_FAILURE = 'CREATE_DESSERT_FAILURE';

export const SYNC_DESSERT_REQUEST = 'SYNC_DESSERT_REQUEST';
export const SYNC_DESSERT_SUCCESS = 'SYNC_DESSERT_SUCCESS';
export const SYNC_DESSERT_FAILURE = 'SYNC_DESSERT_FAILURE';

export const DELETE_DESSERT_REQUEST = 'DELETE_DESSERT_REQUEST';
export const DELETE_DESSERT_SUCCESS = 'DELETE_DESSERT_SUCCESS';
export const DELETE_DESSERT_FAILURE = 'DELETE_DESSERT_FAILURE';

export const UPDATE_DESSERT = 'UPDATE_DESSERT';
export const UPDATE_DESSERT_STATE = 'UPDATE_DESSERT_STATE';

export const UPDATE_STORY = 'UPDATE_STORY';
export const UPDATE_STORY_STATE = 'UPDATE_STORY_STATE';
export const DELETE_STORY_STATE = 'DELETE_STORY_STATE';
export const INIT_STORY = 'INIT_STORY';

export const DELETE_STORY_REQUEST = 'DELETE_STORY_REQUEST';
export const DELETE_STORY_SUCCESS = 'DELETE_STORY_SUCCESS';
export const DELETE_STORY_FAILURE = 'DELETE_STORY_FAILURE';

export const UPDATE_TEST_REQUEST = 'UPDATE_TEST_REQUEST';
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS';
export const UPDATE_TEST_FAILURE = 'UPDATE_TEST_FAILURE';

export const DELETE_TEST = 'DELETE_TEST';
export const DELETE_TEST_STATE = 'DELETE_TEST_STATE';
export const CREATE_TEST = 'CREATE_TEST';

export const DELETE_TEST_REQUEST = 'DELETE_TEST_REQUEST';
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS';
export const DELETE_TEST_FAILURE = 'DELETE_TEST_FAILURE';

export const CREATE_TEST_REQUEST = 'CREATE_TEST_REQUEST';
export const CREATE_TEST_SUCCESS = 'CREATE_TEST_SUCCESS';
export const CREATE_TEST_FAILURE = 'CREATE_TEST_FAILURE';

export const SET_CURRENT_ANSWER_STATE = 'SET_CURRENT_ANSWER_STATE';
export const UPDATE_CURRENT_ANSWER_STATE = 'UPDATE_CURRENT_ANSWER_STATE';
export const UPDATE_ANSWER_COVER_CONNECTION_STATE = 'UPDATE_ANSWER_COVER_CONNECTION_STATE';
export const UPDATE_TEST_COVER_CONNECTION_STATE = 'UPDATE_TEST_COVER_CONNECTION_STATE';

export const FETCH_UNITS_REQUEST = 'FETCH_UNITS_REQUEST';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE';

export const FETCH_UNITS_LIST_REQUEST = 'FETCH_UNITS_LIST_REQUEST';
export const FETCH_UNITS_LIST_SUCCESS = 'FETCH_UNITS_LIST_SUCCESS';
export const FETCH_UNITS_LIST_FAILURE = 'FETCH_UNITS_LIST_FAILURE';

export const FETCH_SINGLE_UNIT_REQUEST = 'FETCH_SINGLE_UNIT_REQUEST';
export const FETCH_SINGLE_UNIT_SUCCESS = 'FETCH_SINGLE_UNIT_SUCCESS';
export const FETCH_SINGLE_UNIT_FAILURE = 'FETCH_SINGLE_UNIT_FAILURE';

export const FETCH_SINGLE_COVER_REQUEST = 'FETCH_SINGLE_COVER_REQUEST';
export const FETCH_SINGLE_COVER_SUCCESS = 'FETCH_SINGLE_COVER_SUCCESS';
export const FETCH_SINGLE_COVER_FAILURE = 'FETCH_SINGLE_COVER_FAILURE';

export const ADD_MAJOR_UNIT_REQUEST = 'ADD_MAJOR_UNIT_REQUEST';
export const ADD_MAJOR_UNIT_SUCCESS = 'ADD_MAJOR_UNIT_SUCCESS';
export const ADD_MAJOR_UNIT_FAILURE = 'ADD_MAJOR_UNIT_FAILURE';

export const ADD_MINOR_UNIT_REQUEST = 'ADD_MINOR_UNIT_REQUEST';
export const ADD_MINOR_UNIT_SUCCESS = 'ADD_MINOR_UNIT_SUCCESS';
export const ADD_MINOR_UNIT_FAILURE = 'ADD_MINOR_UNIT_FAILURE';

export const DELETE_MAJOR_UNIT_REQUEST = 'DELETE_MAJOR_UNIT_REQUEST';
export const DELETE_MAJOR_UNIT_SUCCESS = 'DELETE_MAJOR_UNIT_SUCCESS';
export const DELETE_MAJOR_UNIT_FAILURE = 'DELETE_MAJOR_UNIT_FAILURE';

export const DELETE_MINOR_UNIT_REQUEST = 'DELETE_MINOR_UNIT_REQUEST';
export const DELETE_MINOR_UNIT_SUCCESS = 'DELETE_MINOR_UNIT_SUCCESS';
export const DELETE_MINOR_UNIT_FAILURE = 'DELETE_MINOR_UNIT_FAILURE';

export const CREATE_UNIT_REQUEST = 'CREATE_UNIT_REQUEST';
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS';
export const CREATE_UNIT_FAILURE = 'CREATE_UNIT_FAILURE';

export const UNIT_TITLE_UPDATE_REQUEST = 'UNIT_TITLE_UPDATE_REQUEST';
export const UNIT_TITLE_UPDATE_SUCCESS = 'UNIT_TITLE_UPDATE_SUCCESS';
export const UNIT_TITLE_UPDATE_FAILURE = 'UNIT_TITLE_UPDATE_FAILURE';

export const CREATE_BRAINSTORM_COVER_REQUEST = 'CREATE_BRAINSTORM_COVER_REQUEST';
export const CREATE_BRAINSTORM_COVER_SUCCESS = 'CREATE_BRAINSTORM_COVER_SUCCESS';
export const CREATE_BRAINSTORM_COVER_FAILURE = 'CREATE_BRAINSTORM_COVER_FAILURE';

export const DELETE_BRAINSTORM_COVER_REQUEST = 'DELETE_BRAINSTORM_COVER_REQUEST';
export const DELETE_BRAINSTORM_COVER_SUCCESS = 'DELETE_BRAINSTORM_COVER_SUCCESS';
export const DELETE_BRAINSTORM_COVER_FAILURE = 'DELETE_BRAINSTORM_COVER_FAILURE';

export const UPDATE_COVER_REQUEST = 'UPDATE_COVER_REQUEST';
export const UPDATE_COVER_SUCCESS = 'UPDATE_COVER_SUCCESS';
export const UPDATE_COVER_FAILURE = 'UPDATE_COVER_FAILURE';

export const DELETE_COVER_REQUEST = 'DELETE_COVER_REQUEST';
export const DELETE_COVER_SUCCESS = 'DELETE_COVER_SUCCESS';
export const DELETE_COVER_FAILURE = 'DELETE_COVER_FAILURE';

export const FETCH_COVER_COMPONENT_REQUEST = 'FETCH_COVER_COMPONENT_REQUEST';
export const FETCH_COVER_COMPONENT_SUCCESS = 'FETCH_COVER_COMPONENT_SUCCESS';
export const FETCH_COVER_COMPONENT_FAILURE = 'FETCH_COVER_COMPONENT_FAILURE';

export const FETCH_COVER_APPEARS_IN_REQUEST = 'FETCH_COVER_APPEARS_IN_REQUEST';
export const FETCH_COVER_APPEARS_IN_SUCCESS = 'FETCH_COVER_APPEARS_IN_SUCCESS';
export const FETCH_COVER_APPEARS_IN_FAILURE = 'FETCH_COVER_APPEARS_IN_FAILURE';

export const FETCH_COVER_CONTENT_REQUEST = 'FETCH_COVER_CONTENT_REQUEST';
export const FETCH_COVER_CONTENT_SUCCESS = 'FETCH_COVER_CONTENT_SUCCESS';
export const FETCH_COVER_CONTENT_FAILURE = 'FETCH_COVER_CONTENT_FAILURE';

export const FETCH_COVER_CATEGORIES_REQUEST = 'FETCH_COVER_CATEGORIES_REQUEST';
export const FETCH_COVER_CATEGORIES_SUCCESS = 'FETCH_COVER_CATEGORIES_SUCCESS';
export const FETCH_COVER_CATEGORIES_FAILURE = 'FETCH_COVER_CATEGORIES_FAILURE';

export const CREATE_COVER_TEST_REQUEST = 'CREATE_COVER_TEST_REQUEST';
export const CREATE_COVER_TEST_SUCCESS = 'CREATE_COVER_TEST_SUCCESS';
export const CREATE_COVER_TEST_FAILURE = 'CREATE_COVER_TEST_FAILURE';

export const UPDATE_COVER_TEST = 'UPDATE_COVER_TEST';
export const UPDATE_COVER_TEST_STATE = 'UPDATE_COVER_TEST_STATE';

export const UPDATE_COVER_TEST_REQUEST = 'UPDATE_COVER_TEST_REQUEST';
export const UPDATE_COVER_TEST_SUCCESS = 'UPDATE_COVER_TEST_SUCCESS';
export const UPDATE_COVER_TEST_FAILURE = 'UPDATE_COVER_TEST_FAILURE';

export const DELETE_COVER_TEST_REQUEST = 'DELETE_COVER_TEST_REQUEST';
export const DELETE_COVER_TEST_SUCCESS = 'DELETE_COVER_TEST_SUCCESS';
export const DELETE_COVER_TEST_FAILURE = 'DELETE_COVER_TEST_FAILURE';

export const FETCH_PREVIEW_LESSON_REQUEST = 'FETCH_PREVIEW_LESSON_REQUEST';
export const FETCH_PREVIEW_LESSON_SUCCESS = 'FETCH_PREVIEW_LESSON_SUCCESS';
export const FETCH_PREVIEW_LESSON_FAILURE = 'FETCH_PREVIEW_LESSON_FAILURE';

export const FETCH_PREVIEW_CORRECTION_REQUEST = 'FETCH_PREVIEW_CORRECTION_REQUEST';
export const FETCH_PREVIEW_CORRECTION_SUCCESS = 'FETCH_PREVIEW_CORRECTION_SUCCESS';
export const FETCH_PREVIEW_CORRECTION_FAILURE = 'FETCH_PREVIEW_CORRECTION_FAILURE';

export const FETCH_PREVIEW_COVER_REQUEST = 'FETCH_PREVIEW_COVER_REQUEST';
export const FETCH_PREVIEW_COVER_SUCCESS = 'FETCH_PREVIEW_COVER_SUCCESS';
export const FETCH_PREVIEW_COVER_FAILURE = 'FETCH_PREVIEW_COVER_FAILURE';

export const REMOVE_PREVIEW_COVER_HTML = 'REMOVE_PREVIEW_COVER_HTML';
export const REMOVE_PREVIEW_LESSON_HTML = 'REMOVE_PREVIEW_LESSON_HTML';

export const CLEAR_COVER_COMPONENT_STATE = 'CLEAR_COVER_COMPONENT_STATE';

export const SET_CURRENT_EDITING_UNIT = 'SET_CURRENT_EDITING_UNIT';

export const ADD_MINOR_UNIT_STATE = 'ADD_MINOR_UNIT_STATE';
export const DELETE_MINOR_UNIT_STATE = 'DELETE_MINOR_UNIT_STATE';

export const ADD_BRAINSTORM_COVER_STATE = 'ADD_BRAINSTORM_COVER_STATE';
export const DELETE_BRAINSTORM_COVER_STATE = 'DELETE_BRAINSTORM_COVER_STATE';

export const ADD_MINOR_COVER_COVER_TEST_STATE = 'ADD_MINOR_COVER_COVER_TEST_STATE';
export const DELETE_MINOR_COVER_COVER_TEST_STATE = 'DELETE_MINOR_COVER_COVER_TEST_STATE';

export const ADD_MAJOR_COVER_COVER_TEST_STATE = 'ADD_MAJOR_COVER_COVER_TEST_STATE';
export const DELETE_MAJOR_COVER_COVER_TEST_STATE = 'DELETE_MAJOR_COVER_COVER_TEST_STATE';

export const UPDATE_ANSWER_COVER_TEST_CONNECTION_STATE = 'UPDATE_ANSWER_COVER_TEST_CONNECTION_STATE';
export const UPDATE_TEST_COVER_TEST_CONNECTION_STATE = 'UPDATE_TEST_COVER_TEST_CONNECTION_STATE';

export const ADD_LEARNER_TRIGGER = 'ADD_LEARNER_TRIGGER';
export const ADD_LEARNER_TRIGGER_SUCCESS = 'ADD_LEARNER_TRIGGER_SUCCESS';
export const ADD_LEARNER_TRIGGER_FAILURE = 'ADD_LEARNER_TRIGGER_FAILURE';

export const REMOVE_LEARNER_TRIGGER = 'REMOVE_LEARNER_TRIGGER';
export const REMOVE_LEARNER_TRIGGER_SUCCESS = 'REMOVE_LEARNER_TRIGGER_SUCCESS';
export const REMOVE_LEARNER_TRIGGER_FAILURE = 'REMOVE_LEARNER_TRIGGER_FAILURE';

export const UPDATE_LEARNER_TRIGGER_LABEL = 'UPDATE_LEARNER_TRIGGER_LABEL';
export const UPDATE_LEARNER_TRIGGER_LABEL_SUCCESS = 'UPDATE_LEARNER_TRIGGER_LABEL_SUCCESS';
export const UPDATE_LEARNER_TRIGGER_LABEL_FAILURE = 'UPDATE_LEARNER_TRIGGER_LABEL_FAILURE';

export const ADD_AUTHOR_TRIGGER = 'ADD_AUTHOR_TRIGGER';
export const ADD_AUTHOR_TRIGGER_SUCCESS = 'ADD_AUTHOR_TRIGGER_SUCCESS';
export const ADD_AUTHOR_TRIGGER_FAILURE = 'ADD_AUTHOR_TRIGGER_FAILURE';

export const REMOVE_AUTHOR_TRIGGER = 'REMOVE_AUTHOR_TRIGGER';
export const REMOVE_AUTHOR_TRIGGER_SUCCESS = 'REMOVE_AUTHOR_TRIGGER_SUCCESS';
export const REMOVE_AUTHOR_TRIGGER_FAILURE = 'REMOVE_AUTHOR_TRIGGER_FAILURE';

export const UPDATE_LEARNING_ORDER = 'UPDATE_LEARNING_ORDER';
export const UPDATE_LEARNING_ORDER_FAILURE = 'UPDATE_LEARNING_ORDER_FAILURE';
export const UPDATE_LEARNING_ORDER_SUCCESS = 'UPDATE_LEARNING_ORDER_SUCCESS';

export const CREATE_COVER_CATEGORY_REQUEST = 'CREATE_COVER_CATEGORY_REQUEST';
export const CREATE_COVER_CATEGORY_FAILURE = 'CREATE_COVER_CATEGORY_FAILURE';
export const CREATE_COVER_CATEGORY_SUCCESS = 'CREATE_COVER_CATEGORY_SUCCESS';

export const UPDATE_COVER_CATEGORY_REQUEST = 'UPDATE_COVER_CATEGORY_REQUEST';
export const UPDATE_COVER_CATEGORY_FAILURE = 'UPDATE_COVER_CATEGORY_FAILURE';
export const UPDATE_COVER_CATEGORY_SUCCESS = 'UPDATE_COVER_CATEGORY_SUCCESS';

export const PUSH_LESSON_DETAILS = 'PUSH_LESSON_DETAILS';
export const PUSH_STORY_DETAILS = 'PUSH_STORY_DETAILS';

export const COMPENSATE_ACTION = 'COMPENSATE_ACTION';
export const UNSUPPORTED_UNDO = 'UNSUPPORTED_UNDO';

export const ADD_LOCK_REQUEST = 'ADD_LOCK_REQUEST';
export const ADD_LOCK_SUCCESS = 'ADD_LOCK_SUCCESS';
export const ADD_LOCK_FAILURE = 'ADD_LOCK_FAILURE';

export const FETCH_LOCKS_REQUEST = 'FETCH_LOCKS_REQUEST';
export const FETCH_LOCKS_SUCCESS = 'FETCH_LOCKS_SUCCESS';
export const FETCH_LOCKS_FAILURE = 'FETCH_LOCKS_FAILURE';

export const COPY = 'COPY';
export const PASTE = 'PASTE';
export const NO_EFFECT_PASTE = 'NO_EFFECT_PASTE';

export const REGISTER_UNDO = 'REGISTER_UNDO';
export const EXECUTE_UNDO = 'EXECUTE_UNDO';

export const FETCH_THEMES_REQUEST = 'FETCH_THEMES_REQUEST';
export const FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS';
export const FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE';

export const ADD_THEME_REQUEST = 'ADD_THEME_REQUEST';
export const ADD_THEME_SUCCESS = 'ADD_THEME_SUCCESS';
export const ADD_THEME_FAILURE = 'ADD_THEME_FAILURE';

export const ADD_RELATED_THEME_REQUEST = 'ADD_RELATED_THEME_REQUEST';
export const ADD_RELATED_THEME_SUCCESS = 'ADD_RELATED_THEME_SUCCESS';
export const ADD_RELATED_THEME_FAILURE = 'ADD_RELATED_THEME_FAILURE';

export const REMOVE_THEME_REQUEST = 'REMOVE_THEME_REQUEST';
export const REMOVE_THEME_SUCCESS = 'REMOVE_THEME_SUCCESS';
export const REMOVE_THEME_FAILURE = 'REMOVE_THEME_FAILURE';

export const REMOVE_RELATED_THEME_REQUEST = 'REMOVE_RELATED_THEME_REQUEST';
export const REMOVE_RELATED_THEME_SUCCESS = 'REMOVE_RELATED_THEME_SUCCESS';
export const REMOVE_RELATED_THEME_FAILURE = 'REMOVE_RELATED_THEME_FAILURE';

export const CREATE_THEME_REQUEST = 'CREATE_THEME_REQUEST';
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS';
export const CREATE_THEME_FAILURE = 'CREATE_THEME_FAILURE';

export const FETCH_LESSON_OVERVIEW_REQUEST = 'FETCH_LESSON_OVERVIEW_REQUEST';
export const FETCH_LESSON_OVERVIEW_SUCCESS = 'FETCH_LESSON_OVERVIEW_SUCCESS';
export const FETCH_LESSON_OVERVIEW_FAILURE = 'FETCH_LESSON_OVERVIEW_FAILURE';

export const SUBMIT_LESSON_COMMENT_REQUEST = 'SUBMIT_LESSON_COMMENT_REQUEST';
export const SUBMIT_LESSON_COMMENT_SUCCESS = 'SUBMIT_LESSON_COMMENT_SUCCESS';
export const SUBMIT_LESSON_COMMENT_FAILURE = 'SUBMIT_LESSON_COMMENT_FAILURE';

export const FETCH_LESSON_COMMENT_REQUEST = 'FETCH_LESSON_COMMENT_REQUEST';
export const FETCH_LESSON_COMMENT_SUCCESS = 'FETCH_LESSON_COMMENT_SUCCESS';
export const FETCH_LESSON_COMMENT_FAILURE = 'FETCH_LESSON_COMMENT_FAILURE';

export const UPDATE_LESSON_COMMENT_REQUEST = 'UPDATE_LESSON_COMMENT_REQUEST';
export const UPDATE_LESSON_COMMENT_SUCCESS = 'UPDATE_LESSON_COMMENT_SUCCESS';
export const UPDATE_LESSON_COMMENT_FAILURE = 'UPDATE_LESSON_COMMENT_FAILURE';

export const SUBMIT_UNIT_COMMENT_REQUEST = 'SUBMIT_UNIT_COMMENT_REQUEST';
export const SUBMIT_UNIT_COMMENT_SUCCESS = 'SUBMIT_UNIT_COMMENT_SUCCESS';
export const SUBMIT_UNIT_COMMENT_FAILURE = 'SUBMIT_UNIT_COMMENT_FAILURE';

export const FETCH_UNIT_COMMENT_REQUEST = 'FETCH_UNIT_COMMENT_REQUEST';
export const FETCH_UNIT_COMMENT_SUCCESS = 'FETCH_UNIT_COMMENT_SUCCESS';
export const FETCH_UNIT_COMMENT_FAILURE = 'FETCH_UNIT_COMMENT_FAILURE';

export const UPDATE_UNIT_COMMENT_REQUEST = 'UPDATE_UNIT_COMMENT_REQUEST';
export const UPDATE_UNIT_COMMENT_SUCCESS = 'UPDATE_UNIT_COMMENT_SUCCESS';
export const UPDATE_UNIT_COMMENT_FAILURE = 'UPDATE_UNIT_COMMENT_FAILURE';

export const UPDATE_COVER_STATUS = 'UPDATE_COVER_STATUS';
export const UPDATE_COVER_STATUS_FAILURE = 'UPDATE_COVER_STATUS_FAILURE';
export const UPDATE_COVER_STATUS_SUCCESS = 'UPDATE_COVER_STATUS_SUCCESS';

export const MODIFY_SERIES_REQUEST = 'MODIFY_SERIES_REQUEST';
export const MODIFY_SERIES_SUCCESS = 'MODIFY_SERIES_SUCCESS';
export const MODIFY_SERIES_FAILURE = 'MODIFY_SERIES_FAILURE';

export const LESSON_RELATED_LINKS_UPDATE_REQUEST = 'LESSON_RELATED_LINKS_UPDATE_REQUEST';
export const LESSON_RELATED_LINKS_UPDATE_SUCCESS = 'LESSON_RELATED_LINKS_UPDATE_SUCCESS';
export const LESSON_RELATED_LINKS_UPDATE_FAILURE = 'LESSON_RELATED_LINKS_UPDATE_FAILURE';

export const UPDATE_STORY_HINTS = 'UPDATE_STORY_HINTS';

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export const OPEN_GRAIN_EDITOR = 'OPEN_GRAIN_EDITOR';

export const ADD_ANSWER_SET_REQUEST = 'ADD_ANSWER_SET_REQUEST';
export const ADD_ANSWER_SET_SUCCESS = 'ADD_ANSWER_SET_SUCCESS';
export const ADD_ANSWER_SET_FAILURE = 'ADD_ANSWER_SET_FAILURE';

export const REMOVE_ANSWER_SET_REQUEST = 'REMOVE_ANSWER_SET_REQUEST';
export const REMOVE_ANSWER_SET_SUCCESS = 'REMOVE_ANSWER_SET_SUCCESS';
export const REMOVE_ANSWER_SET_FAILURE = 'REMOVE_ANSWER_SET_FAILURE';

export const ADD_ANSWER_REQUEST = 'ADD_ANSWER_REQUEST';
export const ADD_ANSWER_SUCCESS = 'ADD_ANSWER_SUCCESS';
export const ADD_ANSWER_FAILURE = 'ADD_ANSWER_FAILURE';

export const UPDATE_ANSWER_REQUEST = 'UPDATE_ANSWER_REQUEST';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAILURE = 'UPDATE_ANSWER_FAILURE';

export const REMOVE_ANSWER_REQUEST = 'REMOVE_ANSWER_REQUEST';
export const REMOVE_ANSWER_SUCCESS = 'REMOVE_ANSWER_SUCCESS';
export const REMOVE_ANSWER_FAILURE = 'REMOVE_ANSWER_FAILURE';

export const REORDER_ANSWER_REQUEST = 'REORDER_ANSWER_REQUEST';
export const REORDER_ANSWER_SUCCESS = 'REORDER_ANSWER_SUCCESS';
export const REORDER_ANSWER_FAILURE = 'REORDER_ANSWER_FAILURE';

export const ADD_ADVANCED_TEXT_REQUEST = 'ADD_ADVANCED_TEXT_REQUEST';
export const ADD_ADVANCED_TEXT_SUCCESS = 'ADD_ADVANCED_TEXT_SUCCESS';
export const ADD_ADVANCED_TEXT_FAILURE = 'ADD_ADVANCED_TEXT_FAILURE';

export const UPDATE_ADVANCED_TEXT_REQUEST = 'UPDATE_ADVANCED_TEXT_REQUEST';
export const UPDATE_ADVANCED_TEXT_SUCCESS = 'UPDATE_ADVANCED_TEXT_SUCCESS';
export const UPDATE_ADVANCED_TEXT_FAILURE = 'UPDATE_ADVANCED_TEXT_FAILURE';

export const REMOVE_ADVANCED_TEXT_REQUEST = 'REMOVE_ADVANCED_TEXT_REQUEST';
export const REMOVE_ADVANCED_TEXT_SUCCESS = 'REMOVE_ADVANCED_TEXT_SUCCESS';
export const REMOVE_ADVANCED_TEXT_FAILURE = 'REMOVE_ADVANCED_TEXT_FAILURE';

export const WIPEOUT_ADVANCED_TEXT_REQUEST = 'WIPEOUT_ADVANCED_TEXT_REQUEST';
export const WIPEOUT_ADVANCED_TEXT_SUCCESS = 'WIPEOUT_ADVANCED_TEXT_SUCCESS';
export const WIPEOUT_ADVANCED_TEXT_FAILURE = 'WIPEOUT_ADVANCED_TEXT_FAILURE';

export const FETCH_APPEARS_AS_SR_IN_REQUEST = 'FETCH_APPEARS_AS_SR_IN_REQUEST';
export const FETCH_APPEARS_AS_SR_IN_SUCCESS = 'FETCH_APPEARS_AS_SR_IN_SUCCESS';
export const FETCH_APPEARS_AS_SR_IN_FAILURE = 'FETCH_APPEARS_AS_SR_IN_FAILURE';

export const FETCH_APPEARS_AS_HINT_IN_SUCCESS = 'FETCH_APPEARS_AS_HINT_IN_SUCCESS';
export const FETCH_APPEARS_AS_HINT_IN_FAILURE = 'FETCH_APPEARS_AS_HINT_IN_FAILURE';
export const FETCH_APPEARS_AS_HINT_IN_REQUEST = 'FETCH_APPEARS_AS_HINT_IN_REQUEST';

export const FETCH_APPEARS_IN_DESSERT_IN_SUCCESS = 'FETCH_APPEARS_IN_DESSERT_IN_SUCCESS';
export const FETCH_APPEARS_IN_DESSERT_IN_FAILURE = 'FETCH_APPEARS_IN_DESSERT_IN_FAILURE';
export const FETCH_APPEARS_IN_DESSERT_IN_REQUEST = 'FETCH_APPEARS_IN_DESSERT_IN_REQUEST';

export const UPDATE_IO_TAGS_REQUEST = 'UPDATE_IO_TAGS_REQUEST';
export const UPDATE_IO_TAGS_SUCCESS = 'UPDATE_IO_TAGS_SUCCESS';
export const UPDATE_IO_TAGS_FAILURE = 'UPDATE_IO_TAGS_FAILURE';
export const RNS_SHOW_NOTIFICATION = 'RNS_SHOW_NOTIFICATION';

export const UPDATE_COVER_CECR_REQUEST = 'UPDATE_COVER_CECR_REQUEST';
export const UPDATE_COVER_CECR_FAILURE = 'UPDATE_COVER_CECR_FAILURE';
export const UPDATE_COVER_CECR_SUCCESS = 'UPDATE_COVER_CECR_SUCCESS';
