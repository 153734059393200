import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

import IntlGlobalProvider from './IntlGlobalProvider';
import translationsData from './translations.json';
import localStorage from '../localstorage/localstorage';


// Define which locales we support and use.
addLocaleData([...en, ...fr]);

const DEFAULT_LANG = 'en';
const LOCAL_STORAGE_KEY = 'userLanguage';


class StudioIntlProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentWillMount() {
    // If we have no lang, use the default.
    if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, DEFAULT_LANG);
    }
  }

  getCurrentLanguage() {
    return localStorage.getItem(LOCAL_STORAGE_KEY) || DEFAULT_LANG;
  }

  render() {
    const { children } = this.props;

    const currentLanguage = this.getCurrentLanguage();
    const currentTranslations = translationsData[currentLanguage];

    return (
      <IntlProvider locale={currentLanguage} messages={currentTranslations}>
        <IntlGlobalProvider>
          {children}
        </IntlGlobalProvider>
      </IntlProvider>
    );
  }
}

export const LOCAL_STORAGE_USER_LANG_KEY = LOCAL_STORAGE_KEY;

export default StudioIntlProvider;
