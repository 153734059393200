import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import s from './LoadingOverlay.module.pcss';

const LoadingOverlay = ({
  transparent = false,
  local = false,
  message = '',
  className,
  spinnerSize,
}) => (
  <div className={cn(s.loadingContainer, transparent && s.loadingContainerTransparent, local && s.loadingContainerLocal, className)}>
    <CircularProgress color="primary" size={spinnerSize} />
    <Typography type="subheading">{message}</Typography>
  </div>
);

LoadingOverlay.propTypes = {
  transparent: PropTypes.bool,
  local: PropTypes.bool,
  message: PropTypes.string,
  spinnerSize: PropTypes.number,
  className: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  transparent: false,
  local: false,
  message: '',
  spinnerSize: 60,
};

export default LoadingOverlay;
