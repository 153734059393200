import { RESET_ERROR_MESSAGE } from '../../constants';

const initialState = {
  message: null,
};

export default (state = initialState, { type, payload }) => {
  const newState = { ...state };

  if (type.endsWith('_FAILURE')) {
    newState.message = (payload && payload.message) || 'An error occured. Please contact your support.';
  } else if (type === RESET_ERROR_MESSAGE) {
    newState.message = null;
  }

  return newState;
};
